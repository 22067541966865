* {
  margin: 0;
  padding: 0;
  box-sizing:border-box; 
  -webkit-box-sizing:border-box;
  -moz-box-sizing:border-box;  
  color: #333;
}

body {
  height: 100%;
  width: 100%;
  font-family: 'Open Sans', sans-serif; 
  background-color: rgba(235,235,235,1);
}

.bold {
  font-weight: bold;
}

.clear{
  clear:both;
}

/* Page */

.page {
  height: 100vh;
  padding: calc(15% + 1.5em) 5% 5%;
  overflow-y: auto;
  position: fixed;
  top: 0;
  width: 100%;
  -webkit-overflow-scrolling: touch;
  transition: transform 1s ease-in-out;
}

.page-enter {
  transform: translate(-100%, 0);
}

.page-enter-active {
  transform: translate(0, 0);
}

.page-exit {
  transform: translate(-100%, 0);
}

.page--prev.page-enter {
  transform: translate(100%, 0);
}

.page--prev.page-enter-active {
  transform: translate(0, 0);
}

.page--prev.page-exit {
  transform: translate(100%, 0);
}