
.calculator{
  max-width: 450px; 
  position: relative;
  margin: 0 auto;
}

.logo{
  margin: 25px 0px;
  text-align: center;
}

.logo i{
  display: block;
  height: 140px;
  background-image: url("../../assets/pokemon-go-logo-transparent.png");
  background-repeat: no-repeat;
  background-position: 50% 50%;
  background-size: contain;
  color: rgba(0,0,0,0);
}

label{
  font-size: 18px;
}

input{
  font-size: 16px;
  padding: 0px 10px;
  height: 40px;
  width: 100%;
  border: 1px solid #888;
  border-radius: 2px;
  outline: none;
}

input:focus{
  border-color: rgba(238,21,21,1);
}

.form-div{
  margin: 20px;
  
  label{
    display: block;
    margin: 0px 0px 5px;
  }

  &.button input{
    border-color: rgba(238,21,21,1);
    background-color: rgba(238,21,21,1);
    color: #FFF;
    height: 50px;
    line-height: 50px;
    font-size: 18px;
    cursor: pointer;

    &:hover{
      -moz-box-shadow: inset 0px 0px 500px rgba(0,0,0,.15);
      -webkit-box-shadow: inset 0px 0px 500px rgba(0,0,0,.15);
      box-shadow: inset 0px 0px 500px rgba(0,0,0,.15);
    }
    &:active{
      -moz-box-shadow: inset 0px 0px 500px rgba(0,0,0,.3);
      -webkit-box-shadow: inset 0px 0px 500px rgba(0,0,0,.3);
      box-shadow: inset 0px 0px 500px rgba(0,0,0,.3);
    }
  }
}

.language-option{
  float: right;
  margin: 0px 20px 20px 0px;
  p{
    padding: 0px 10px;
    display: inline-block;
  }
  select{
    font-size: 16px;
    display: inline-block;
    height: 40px;
  }
}

.bottom-info{
  text-align: center;
  li{
    list-style: none;
  }
}